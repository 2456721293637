import React from "react";
import "../styles/Contact.css";


const Contact = () => {
  return (
    <div id="contact">
      <div className="contact-title">get in touch.</div>
      <div className="oval-contact">
        <span className="oval-text">say hello</span>
      </div>
    </div>
  );
};

export default Contact;
